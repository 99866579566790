/**
 * Palette is where the base colors live
 * Palette color names should be descriptive of the color,
 * i.e. it should have the name of color in it and match closely
 * to the Figma style guide
 */

/**
 * key name starting w _ denotes helper to make it easy to
 * add decriptions for storybook stories with loops
 */

export enum Palette {
  primary100 = '#9EBD93',
  primary200 = '#82AA75',
  primary250 = '#86b57f',
  primary300 = '#6AA753',
  primary400 = '#4EA550',
  primary500 = '#6D965F',
  primaryLight100 = '#A4C79F',
  primaryLight200 = '#95BE8F',

  // utility palette
  _utility = 'helper for storybook loops',

  redHover = '#DF3022',
  checkboxWarning = '#F04A5E',
  secondaryActive = '#774FBF',
  secondaryInformative = '#3273F6',
  secondaryNotice = '#F3AF4F',
  secondaryWarning = '#D32D1F',
  tertiaryWarning = '#dc7b7b',
  trafficLightOrange = '#FFA500',
  trafficLightRed = '#FF5555',
  heartRed = '#E60000',
  starOrange = 'oklch(79.27% 0.17 70.64)',
  starOrangeLight = 'oklch(86.47% 0.101 70.64)',
  violet = '#AC29D5',
  blueberry = '#3945D6',
  midnight = '#4E6291',
  teal = '#8EB8B8',
  chartreuse = '#B9F155',
  pear = '#A3D47C',
  pickle = '#799B2E',
  pickleLighter = '#76ab6f',
  olive = '#777620',
  heatheredGray = '#938D85',
  greenYellow = '#ADFF2F',
  greenMint = '#89fa06',
  errorRed = '#FF99AA',
  darkGreen = '#659926',

  cinnamon = '#B35E4D',
  peach = '#CF807D',
  coral = '#EBB189',
  gold = '#F2C45D',
  ocean = '#5271B3',
  ice = '#AEE0F9',
  orchid = '#A160A0',
  vine = '#CBDFC9',
  lemon = '#D1E280',
  lime = '#71B773',
  forest = '#6D965F',
  warning = '#FCECEF',
  alabaster = '#F2F1E6',
  whitesmoke = '#f5f5f5',
  whitesmokeDarker = '#f8f8f2',
  greenyellowLighter = '#79C800',
  carrotOrange = '#f48a2b',
  forestGreen = '#1d9a35',
  chestnutRose = '#cf4e4c',

  darkBlue = '#1B363F',
  green20 = '#8ac13e',
  blue20 = '#3d91c2',

  // grayscale
  _grayscale = 'helper for storybook loops',

  black = '#000000',
  gray5 = '#444444',
  gray7 = '#8d8d8d',
  gray10 = '#333333',
  gray12 = '#454545',
  gray15 = '#555',
  gray20 = '#575757',
  gray25 = '#555555',
  gray30 = '#757575',
  gray32 = '#777777',
  gray35 = '#8f8f8f',
  gray40 = '#908A83',
  gray50 = '#999999',
  gray55 = '#AAAAAA',
  gray60 = '#BBBBAC',
  gray70 = '#C5C5C5',
  gray75 = '#EBEBEB',
  gray80 = '#EEEEEE',
  gray81 = '#EFEFEF',
  gray82 = '#CCCCCC',
  gray84 = '#EAEAEA',
  gray86 = '#F5F5EE',
  gray90 = '#F4F4F4',
  gray92 = '#E6EBF1',
  gray91 = '#E6E6E6',
  gray94 = '#DDDDDD',
  gray95 = '#F2F2F2',
  gray98 = '#F5F4EE',
  gray99 = '#FAFAFA',
  gray110 = '#E8E6E0',
  gray120 = '#D2CEC3',
  gray130 = '#F0F0F0',
  gray140 = '#D6D6D6',
  gray150 = '#747474',
  gray160 = '#E2E1D6',

  white = '#FFFFFF',
  transparent = 'rgba(0,0,0,0)',
  transparentWhite = 'rgba(245, 245, 245, 0.5)',
  transparentWhiteLight = 'rgba(245, 245, 245, 0.2)',

  transparentBlack = 'rgba(0, 0, 0, 0.7)',
  black20 = '#222222',

  orange = '#FFAB34',
}
