import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@/components/base/box';
import { Flex } from '@/components/base/flex';
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faYoutube,
  faPinterest,
} from '@fortawesome/free-brands-svg-icons';

import { Image } from '@/components/base/image';
import { TextLink } from '@/components/base/text-link';

import { useTheme } from '@emotion/react';

import {
  ABOUT_PAGE,
  BLOG_PAGE,
  CONTACT_PAGE,
  HOW_IT_WORKS_PAGE,
  MARKETING_PAGE,
  NEWS_PAGE,
  PRIVACY_PAGE,
} from '@/constants/routes';
import { Text } from '@/components/base/text';
import { FOOTER_MAX_WIDTH } from '@/constants/layout';

export const Logo = () => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      marginY="m"
    >
      <TextLink href={MARKETING_PAGE}>
        <Image
          cursor="pointer"
          src="/images/dntWhiteLogo.png"
          alt="Dinner Time Logo"
          width="120px"
          height="47px"
        />
      </TextLink>
      <Text color="textTertiary" variant="5xsLight" marginTop="m">
        &copy;{new Date().getFullYear()} DinnerTime
      </Text>
    </Flex>
  );
};

const SocialMedia = () => {
  const theme = useTheme();
  const iconColor = theme.colors.iconColor;

  const FlexWithGap = styled(Flex)`
    gap: ${({ theme }) => theme.space.s}px;
  `;

  return (
    <FlexWithGap>
      <TextLink href="http://www.facebook.com/mydinnertime" target="_blank">
        <FontAwesomeIcon icon={faFacebookF} color={iconColor} size="xl" />
      </TextLink>
      <TextLink href="http://www.twitter.com/MyDinnerTime" target="_blank">
        <FontAwesomeIcon icon={faTwitter} color={iconColor} size="xl" />
      </TextLink>
      <TextLink
        href="http://www.linkedin.com/company/dinnertime-inc-"
        target="_blank"
      >
        <FontAwesomeIcon icon={faLinkedinIn} color={iconColor} size="xl" />
      </TextLink>
      <TextLink
        href="http://www.youtube.com/user/mydinnertimesolution"
        target="_blank"
      >
        <FontAwesomeIcon icon={faYoutube} color={iconColor} size="xl" />
      </TextLink>
      <TextLink href="http://pinterest.com/mydinnertime" target="_blank">
        <FontAwesomeIcon icon={faPinterest} color={iconColor} size="xl" />
      </TextLink>
    </FlexWithGap>
  );
};

const Links = () => {
  const FlexWithGap = styled(Flex)`
    gap: ${({ theme }) => theme.space.m}px;
  `;

  return (
    <FlexWithGap
      flexDirection={['column', 'column', 'row']}
      alignItems="center"
      flexWrap="wrap"
    >
      <TextLink href={MARKETING_PAGE} target="_blank" variant="sLight">
        Home
      </TextLink>
      <TextLink href={HOW_IT_WORKS_PAGE} target="_blank" variant="sLight">
        How It Works
      </TextLink>
      <TextLink href={ABOUT_PAGE} target="_blank" variant="sLight">
        About
      </TextLink>
      <TextLink href={BLOG_PAGE} target="_blank" variant="sLight">
        Blog
      </TextLink>
      <TextLink href={NEWS_PAGE} target="_blank" variant="sLight">
        News
      </TextLink>
      <TextLink href={PRIVACY_PAGE} target="_blank" variant="sLight">
        Privacy
      </TextLink>
      <TextLink href={CONTACT_PAGE} target="_blank" variant="sLight">
        Contact
      </TextLink>
    </FlexWithGap>
  );
};

const FooterWraper = styled(Box)`
  ${({ theme }) => theme.mediaQueries.print} {
    display: none;
  }
`;

export const Footer = () => {
  const FlexWithGap = styled(Flex)`
    gap: ${({ theme }) => theme.space['2xl']}px;
  `;

  return (
    <FooterWraper>
      <Box
        backgroundColor="footerBackgroundColor"
        padding="2xl"
        paddingBottom={['8xl', '8xl', '2xl']}
      >
        <FlexWithGap
          justifyContent="space-between"
          alignItems="center"
          maxWidth={FOOTER_MAX_WIDTH}
          marginX="auto"
          flexDirection={['column', 'column', 'row']}
        >
          <Box display={['block', 'block', 'none']}>
            <SocialMedia />
          </Box>
          <Box display={['none', 'none', 'block']}>
            <Logo />
          </Box>

          <Links />

          <Box display={['none', 'none', 'block']}>
            <SocialMedia />
          </Box>
          <Box display={['block', 'block', 'none']}>
            <Logo />
          </Box>
        </FlexWithGap>
        <Flex alignItems="center" justifyContent="center" marginTop="l">
          <Text variant="5xsLight" color="textTertiary" textAlign="center">
            Heart-Check certification does not apply to information or links
            unless expressly stated. Heart-Check certified food products and
            recipes are updated approximately twice per month.
          </Text>
        </Flex>
      </Box>
    </FooterWraper>
  );
};
