import { Theme } from '@/types/theme-types';

// TODO replace the values with the appropriate ones
export const radii: Theme['radii'] = {
  none: '0px',
  '4xs': '1px',
  '3xs': '2px',
  '2xs': '4px',
  xs: '8px',
  s: '16px',
  m: '24px',
  l: '32px',
  xl: '40px',
  '2xl': '48px',
  '3xl': '56px',
  '4xl': '60px',
  '5xl': '100px',
  '50%': '50%',
};
