import { Theme } from '@/types/theme-types';

// TODO replace the values with the appropriate ones
export const space: Theme['space'] = {
  none: 0,
  '4xs': 1,
  '3xs': 2,
  '2xs': 4,
  xs: 8,
  s: 12,
  m: 16,
  l: 20,
  xl: 24,
  '2xl': 28,
  '3xl': 32,
  '4xl': 40,
  '5xl': 48,
  '6xl': 64,
  '7xl': 72,
  '8xl': 80,
  '9xl': 120,
  '10xl': 180,
  auto: 'auto',
};
