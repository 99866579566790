import { Palette } from '@/constants/palette';
import { Theme } from '@/types/theme-types';

/**
 * Colors are generically named (not descriptive of color) to tie
 * closely to their intended use in the UI/ align with the style guide.
 * If we ever add a new theme, i.e. "dark", we keep the same names and
 * simply swap out the Palette color values
 */

// TODO replace the values with the appropriate ones
export const colors: Theme['colors'] = {
  primary: Palette.secondaryInformative,
  primary100: Palette.primary100,
  primary200: Palette.primary200,
  primary250: Palette.primary250,
  primary300: Palette.primary300,
  primary400: Palette.primary400,
  primary500: Palette.primary500,
  primaryLight100: Palette.primaryLight100,
  primaryLight200: Palette.primaryLight200,
  inputBorder: Palette.gray82,
  modalHeader: Palette.gray30,
  textGray20: Palette.gray20,
  closerHover: Palette.gray20,
  modalFooter: Palette.gray86,
  lightBorder: Palette.gray94,
  warningBtnText: Palette.gray10,
  warningBtnHover: Palette.redHover,
  textLink: Palette.primary300,
  iconBtnHover: Palette.gray90,
  iconBtnBorder: Palette.gray30,
  iconBars: Palette.gray30,
  dietaryCardBg: Palette.gray84,
  iconBarsBg: Palette.gray91,
  secondary: Palette.white,
  textTertiary: Palette.gray82,
  overlayBGColor: Palette.black,
  inputCardShadow: Palette.gray92,
  navBackgroundHover: Palette.gray90,
  navText: Palette.gray10,
  profileLabel: Palette.gray10,
  secondaryLight: Palette.gray30,
  secondaryWarning: Palette.orange,

  textQuaternary: Palette.gray15,
  checkboxLabelHover: Palette.gray15,
  signinTextColor: Palette.gray55,
  // this our brand color
  brand: Palette.secondaryInformative,
  hover: Palette.secondaryInformative,
  active: Palette.secondaryInformative,
  focus: Palette.secondaryInformative,
  visited: Palette.secondaryInformative,
  disabled: Palette.gray80,
  warning: Palette.secondaryWarning,
  warningBadgeBg: Palette.warning,
  error: Palette.errorRed,
  link: Palette.white,
  linkSecondary: Palette.greenYellow,
  text: Palette.black,
  textSecondary: Palette.gray30,
  iconColor: Palette.white,
  transparent: 'transparent',
  divider: Palette.gray40,
  cta: Palette.gray10,
  borderColor: Palette.gray70,
  disabledIconColor: Palette.gray40,
  searchInputBackgroundColor: Palette.gray80,
  searchLabelColor: Palette.gray40,
  optionHoverColor: Palette.gray90,
  badgeColor: Palette.secondaryNotice,
  tabBackgroundColor: Palette.gray90,
  tabBorderColor: Palette.gray80,
  tabTextColor: Palette.gray40,
  footerBackgroundColor: Palette.heatheredGray,
  headerBackgroundColor: Palette.gray98,
  pageBackgroundColor: Palette.gray99,
  pageSecondaryBackgroundColor: Palette.alabaster,
  householdQuestionaryBackgroundColor: Palette.gray81,
  checkedCheckboxBackgroundColor: Palette.primary100,
  checkedRadioBorderColor: Palette.primary100,
  checkboxOutlineColor: Palette.gray20,
  checkboxBorderColor: Palette.gray60,
  erroredCheckboxBackgroundColor: Palette.errorRed,
  checkboxBackgroundColor: Palette.white,
  radioBackgroundColor: Palette.white,
  checkboxLabelColor: Palette.gray10,
  checkedCheckboxLabelColor: Palette.primary100,
  radioLabelColor: Palette.gray10,
  radioBorderDisabledColor: Palette.gray10,
  checkedRadioLabelColor: Palette.primary100,
  collapseTitleBackground: Palette.primaryLight200,
  ctaOutline: Palette.primary300,
  tabItemColor: Palette.gray50,
  tabItemBackgroundColor: Palette.transparentWhite,
  quickstartHeaderBackgroundColor: Palette.alabaster,
  quickstartTabShadowColor: Palette.gray50,
  quickstartBorderColor: Palette.gray75,
  primaryInputBackgroundColor: Palette.white,
  secondaryInputBackgroundColor: Palette.white,
  primaryInputBorderColor: Palette.gray82,
  infoIconBackgroundColor: Palette.gray81,
  infoIconColor: Palette.gray50,
  collapseShadowColor: Palette.gray94,
  planBackgroundColor: Palette.gray15,
  planTextColor: Palette.gray15,
  textGray32: Palette.gray32,
  textGray55: Palette.gray55,
  buttonGray35: Palette.gray35,
  calendarDefaultText: Palette.gray12,
  calendarTodayText: Palette.olive,
  calendarBorder: Palette.gray80,
  calendarTileDefaultText: Palette.gray12,
  calendarTileTodayText: Palette.olive,
  calendarTileHover: Palette.primaryLight200,
  calendarTileBackground: Palette.gray80,
  calendarTileBorder: Palette.gray82,
  calendarIconHover: Palette.primary200,
  white: Palette.white,
  gray82: Palette.gray82,
  cardTitleBorderColor: Palette.gray94,
  cardTitleBorderActiveColor: Palette.gray55,
  cardTitleColor: Palette.gray10,
  tooltipBackground: Palette.black,
  tooltipTextColor: Palette.white,
  planCalendarActionTextColor: Palette.gray15,
  ratingInitialFillColor: Palette.gray30,
  ratingEmptyColor: Palette.gray94,
  ratingFillColor: Palette.gold,
  planCalendarActionItemHover: Palette.gray80,
  planCalendarActionMoveColor: Palette.gray15,
  planCalendarActionMoveHereBackground: Palette.primary250,
  planCalendarDayLoaderBackground: Palette.gray99,
  planCalendarDayRemoveDinnerHover: Palette.redHover,
  dinnerRecipeBoxLeftArrowColor: Palette.gray30,
  dinnerRecipeBoxTitleItalicColor: Palette.gray32,
  dinnerRecipeBoxTitleColor: Palette.gray5,
  dinnerRecipeBoxBackgroundColor: Palette.alabaster,
  dinnerRecipeBoxDishTitleColor: Palette.gray20,
  dinnerRecipeBoxDishActionsColor: Palette.gray10,
  dinnerRecipeBoxDishActionRemoveButtonHoverColor: Palette.redHover,
  dinnerRecipeBoxRecipeStatSeparatorColor: Palette.gray80,
  dinnerRecipeBoxBottomActionServingsButtonInputLabelBackground: Palette.gray25,
  dinnerRecipeBoxNutritionFactBackground: Palette.whitesmokeDarker,
  dinnerRecipeBoxNutritionFactBorder: Palette.whitesmoke,
  dinnerRecipeTitleBackgroundColor: Palette.gray32,
  dinnerRecipePrepBackground: Palette.whitesmoke,
  tabsText: Palette.gray20,
  tabsListBackground: Palette.alabaster,
  tabsTabBackground: Palette.gray110,
  recipeSearchFavoriteTabMessageGreen: Palette.primaryLight200,
  recipeSearchFavoriteTabFiltersBorderBottom: Palette.gray94,
  recipeSearchFavoriteTabFiltersText: Palette.gray30,
  recipeSearchFavoriteTabFiltersIconWrapBorder: Palette.gray82,
  recipeSearchFavoriteTabFiltersIconWrapHoverBackground: Palette.gray95,
  recipeSearchFavoriteTabMessageText: Palette.gray20,
  recipeSearchFavoriteTabMessageTitle: Palette.gray20,
  textInputClear: Palette.gray50,
  textInputClearHover: Palette.gray30,
  linkDarkGreen: Palette.darkGreen,
  navItemActionBackground: Palette.transparentWhiteLight,
  groceryListSectionHeaderColor: Palette.gray50,
  groceryListSectionContentRowColor: Palette.gray99,
  groceryListSectionUserRowColor: Palette.gray130,
  groceryListSectionContentRowHover: Palette.white,
  groceryListSectionFooterColor: Palette.gray94,
  tabsBorderColor: Palette.gray120,
  scoreCardSecondSummaryBackground: Palette.gray7,
  scoreCardSecondSummaryInstructionsBackground: Palette.gray30,
  scoreCardSecondSummaryTotalPointsBackground: Palette.pickleLighter,
  scoreCardBreakdownItemOddBackground: Palette.gray75,
  pantryCollapseBackground: Palette.gray30,
  pantryCollapseIconBackground: Palette.gray110,
  pantryBackground: Palette.alabaster,
  pantryHeaderBackground: Palette.gray110,
  pantrySwitchActive: Palette.tertiaryWarning,
  pantrySwitchActive2: Palette.gold,
  pantrySearchResultSectionIcon: Palette.greenMint,
  pantrySearchResultItemIcon: Palette.gold,
  shoppingListItemSaleColor: Palette.orange,
  trafficLightOrange: Palette.trafficLightOrange,
  trafficLightRed: Palette.trafficLightRed,
  heartRed: Palette.heartRed,

  settingsBackgroundColor: Palette.transparentBlack,
  settingsTabColor: Palette.greenYellow,
  settingsTabHoverColor: Palette.greenyellowLighter,
  settingsTabActiveColor: Palette.gray25,
  recipeBoxFilterOptionsText: Palette.gray30,
  recipeBoxFilterOptionsBackground: Palette.gray99,
  recipeBoxFilterOptionsSectionTitleBorderBottom: Palette.gray80,
  recipeBoxFilterBugBackground: Palette.gray99,
  recipeBoxFilterBugText: Palette.gray10,
  recipeBoxFilterBugBorder: Palette.gray94,
  starOrange: Palette.starOrange,
  starOrangeLight: Palette.starOrangeLight,
  homeMenuIconWrapBackground: Palette.primary250,
  homeMenuIconWrapDisabledBackground: Palette.gray140,
  homeMenuIconWrapShadow: Palette.gray94,
  homeMenuTitle: Palette.gray30,
  homeMenuSubTitle: Palette.gray55,
  notificationInfo: Palette.carrotOrange,
  notificationSuccess: Palette.forestGreen,
  notificationError: Palette.chestnutRose,
  black: Palette.black,
  starDark: Palette.gray25,
  rateRecipesToolTipText: Palette.gray25,

  runningLowBannerBackground: Palette.gray25,
  runningLowButtonBackground: Palette.orange,
  recipeDetailsHeroBorderBottom: Palette.gray80,
  recipeDetailsRatingText: Palette.gray150,
  recipeDetailsGrayBorder: Palette.gray160,
  hrBorder: Palette.gray80,
  bodyText: Palette.gray10,
  addMealToGroceryTripBorderColor: Palette.gray80,
  saleBackground: Palette.orange,
  mealDatePickerCalendarNavigationBackground: Palette.darkBlue,
  mealDatePickerCalendarWeekDayBackground: Palette.gray30,
  mealDatePickerCalendarTileText: Palette.gray82,
  mealDatePickerCalendarTileBackground: Palette.gray130,
  mealDatePickerCalendarTileCustomContentHoverText: Palette.black20,
  mealDatePickerCalendarTileCustomContentHoverBackground: Palette.gray84,
  mealDatePickerCalendarTileShoppingTrip: Palette.green20,
  mealDatePickerCalendarTileMealPlaned: Palette.blue20,
  mealDatePickerLegendBackground: Palette.whitesmoke,
  transparentWhite: Palette.transparentWhite,
  transparentWhiteLight: Palette.transparentWhiteLight,
};
