import React, { ReactNode } from 'react';
import { Box } from '@/components/base/box';

import styled from '@emotion/styled';
import { Theme } from '@/types/theme-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Flex } from '@/components/base/flex';

export type NotificationType = 'info' | 'success' | 'error';

type StyledWrapperProps = {
  type?: NotificationType;
};
const StyledWrapper = styled(Flex)<StyledWrapperProps>`
  background: ${({ type, theme }) => getBgColor(theme, type)};
  padding: 18px 30px 18px ${({ theme }) => theme.space.m}px;
  position: relative;
`;

const StyledTextWrap = styled(Box)`
  color: ${({ theme }) => theme.colors.white};

  & span {
    font-size: ${({ theme }) => theme.fontSizes['2xl']};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    line-height: ${({ theme }) => theme.lineHeights['2xs']};
    vertical-align: top;
  }
`;

const StyledCloseWrap = styled(Box)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
`;

const getBgColor = (theme: Theme, type?: NotificationType) => {
  switch (type) {
    case 'info':
      return theme.colors.notificationInfo;
    case 'success':
      return theme.colors.notificationSuccess;
    case 'error':
      return theme.colors.notificationError;
    default:
      return theme.colors.notificationInfo;
  }
};

export type NotificationProps = {
  text: string;
  type?: NotificationType;
  rightContent?: ReactNode;
  showClose?: boolean;
  onClose?: () => void;
};

export const Notification: React.FC<NotificationProps> = ({
  text,
  type,
  rightContent,
  showClose = true,
  onClose = () => {},
}) => {
  return (
    <StyledWrapper
      type={type}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <StyledTextWrap
        dangerouslySetInnerHTML={{ __html: text }}
      ></StyledTextWrap>

      <Flex>
        {rightContent && <Box marginRight={'s'}>{rightContent}</Box>}
        {showClose && (
          <StyledCloseWrap onClick={() => onClose()}>
            <FontAwesomeIcon icon={faTimes} size="sm" />
          </StyledCloseWrap>
        )}
      </Flex>
    </StyledWrapper>
  );
};
