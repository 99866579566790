import { Palette } from '@/constants/palette';
import { Theme } from '@/types/theme-types';

export const input: Theme['input'] = {
  variants: {
    primary: {
      backgroundColor: Palette.white,
      borderColor: Palette.gray82,
      disabledColor: Palette.gray80,
      errorColor: Palette.errorRed,
      color: Palette.gray15,
      disabledLabelColor: Palette.gray60,
      borderFocusColor: Palette.ice,
      borderHoverColor: Palette.ice,
      focusedColor: Palette.black,
      helperColor: Palette.white,
      labelColor: Palette.gray10,
    },
    secondary: {
      backgroundColor: Palette.white,
      borderColor: Palette.white,
      disabledColor: Palette.gray80,
      errorColor: Palette.errorRed,
      color: Palette.gray20,
      disabledLabelColor: Palette.gray60,
      borderFocusColor: Palette.primary100,
      borderHoverColor: Palette.primary100,
      focusedColor: Palette.black,
      helperColor: Palette.white,
      labelColor: Palette.white,
    },
  },
};
