import { AxiosError } from 'axios';

/**
 * Base class for application errors
 */
export class DinnertimeError extends Error {}

/**
 */
export class ApiError<T = unknown> extends DinnertimeError {
  constructor(cause: AxiosError<T>, message?: string) {
    super(message ?? `ApiError: ${cause.message}`);
    this.cause = cause;
  }

  cause: AxiosError;
  get data() {
    return this.cause.response?.data;
  }

  get status() {
    return this.cause.response?.status;
  }

  get statusText() {
    return this.cause.response?.statusText;
  }

  get headers() {
    return this.cause.response?.headers;
  }

  get config() {
    return this.cause.response?.config;
  }
  get request() {
    return this.cause.request;
  }
}

export function isAxiosError(e: unknown): e is AxiosError {
  if (typeof e !== 'object') {
    return false;
  }
  return !!(e as any).isAxiosError;
}

export class MissingProviderError extends Error {
  providerName: string;
  constructor(providerName: string) {
    super(
      `Provider ${providerName} is missing from react tree. Make sure it is declared`,
    );
    this.providerName = providerName;
  }
}
