import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AuthService from '@/services/auth-service';
import { Box } from '@/components/base/box';
import { memoize } from 'lodash';
import { UrlObject } from 'url';
import { useAuthorizationState } from '@/hooks/auth/use-authorization-guard';

type AuthGuardProps = {
  children: JSX.Element;
};

const StyledLoading = styled(Box)`
  & > svg > path {
    color: ${({ theme }) => theme.colors.gray82};
  }
`;

StyledLoading.defaultProps = {
  marginY: '8xl',
  marginX: 'auto',
  textAlign: 'center',
};

export const AuthGuard = ({ children }: AuthGuardProps) => {
  const router = useRouter();
  const [isLoaded, setIsLoaded] = useState(false);
  const { getPermissionCheck } = useAuthorizationState();

  useEffect(() => {
    const hideContent = () => setIsLoaded(false);
    authCheck(router.asPath);

    // Note: on routeChangeStart - hide page, check auth
    router.events.on('routeChangeStart', hideContent);
    router.events.on('routeChangeComplete', authCheck);

    return () => {
      router.events.off('routeChangeStart', hideContent);
      router.events.off('routeChangeComplete', authCheck);
    };
  }, []);

  function authCheck(url: string) {
    const path = url.split('?')[0];
    const permissions = getPermissionCheck(path);
    if (!permissions.isAuthorized) {
      setIsLoaded(false);
      router.push({
        pathname: permissions.redirectTo,
      });
    } else {
      setIsLoaded(true);
    }
  }

  return (
    (isLoaded && children) || (
      <StyledLoading>
        <FontAwesomeIcon spinPulse={true} icon={faSpinner} size="2xl" />
      </StyledLoading>
    )
  );
};
