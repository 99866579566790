/***
 * TODO: This component is in *major* need of refactoring. Rather than being a div or span which
 * passes styling to child components/elements, or be a higher ordered component which can be instantiated
 * for each tag type needed, it dynamically changes its base element type using the 'as' prop. This can't be
 * easily represented in typescript, which leads to overuse of the ts-ignore comment to get around static
 * type restrictions. Moreover, it leads to incorrect html markup with certain elements having disallowed children
 * (i.e. <button> descendent of a <button> or block elements inside a <p>)
 **/

import styled from '@emotion/styled';
import {
  compose,
  display,
  color,
  space,
  textAlign,
  variant,
  gridArea,
  DisplayProps,
  ColorProps,
  SpaceProps,
  system,
  GridAreaProps,
  TextAlignProps,
} from 'styled-system';
import { StandardLonghandProperties } from 'csstype';
import css from '@styled-system/css';
import { TextVariantKey, TextTags } from '@/types/theme-types';

export interface ExtraTextProps {
  cursor?: StandardLonghandProperties['cursor'];
  /**
   * Controlling font size, line height and font weight.
   * Possible variants: 4xl, 4xlBold, 3xl, 3xlBold, 2xl, 2xlBold, xl,
   * xlBold, l, lBold, m, mBold, s, sBold, xs, xsBold, 2xs, 2xsBold.
   */
  variant?: TextVariantKey | Array<TextVariantKey>;
  /**
   * Tag type, currently support h1, h2, ..., h6, span, label and p.
   */
  as?: TextTags;
  /**
   * Prevents errors for when using the Text component as labels and allows
   * for form components to reuse styling and variants as defined here.
   */
  htmlFor?: string;

  /**
   * id prop for the Text component
   */
  id?: string;
}
export type TextProps = React.PropsWithChildren<
  DisplayProps &
    React.HTMLProps<HTMLAnchorElement> &
    ColorProps &
    SpaceProps &
    GridAreaProps &
    TextAlignProps &
    ExtraTextProps
>;

const baseCss = css({
  fontFamily: 'body',
  m: 0,
});
const TextComponent = styled.div<ExtraTextProps>`
  ${baseCss}

  ${({ theme }) =>
    compose(
      display,
      color,
      space,
      gridArea,
      textAlign,
      variant({
        variants: theme.textVariants,
      }),
    )};
  ${system({
    cursor: true,
  })}
`;

export const BaseText = TextComponent;
export const Text: React.FC<TextProps> = (props) => {
  const fullProps = { as: 'p', ...props };
  // @ts-ignore
  return <TextComponent {...fullProps} />;
};

Text.defaultProps = {
  variant: 's',
  color: 'text',
};

export type HeadingProps = Omit<TextProps, 'variant' | 'as'>;

export const H1: React.FC<HeadingProps> = (props) => {
  return <Text {...props} as="h1" variant={['m', 'm', 'm', '3xl']} />;
};

export const H2: React.FC<HeadingProps> = (props) => {
  return <Text {...props} as="h2" variant={['s', 's', 's', 'xl']} />;
};

export const H3: React.FC<HeadingProps> = (props) => {
  return <Text {...props} as="h3" variant={['xs', 'xs', 'xs', 'l']} />;
};

export type PProps = Omit<TextProps, 'as' | 'variant'>;

export const P: React.FC<PProps> = (props) => {
  return <Text {...props} as="p" variant={['2xs', '2xs', '2xs', 's']} />;
};

export const ItalicText = styled(Text)`
  font-style: italic;
`;
