import { create } from 'zustand';

interface ScoreCardState {
  viewScoreCardModal: boolean;
  setViewScoreCardModal: (viewScoreCardModal: boolean) => void;
}

export const useScoreCardStore = create<ScoreCardState>((set) => {
  return {
    viewScoreCardModal: false,
    setViewScoreCardModal: (viewScoreCardModal) => set({ viewScoreCardModal }),
  };
});
