import { usePromoNotificationStore } from '@/store/store-providers';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NextLink from 'next/link';
import { useCallback, useEffect } from 'react';
import { Box } from '../base/box';
import { Button } from '../base/buttons';
import { Flex } from '../base/flex';
import { TextLink } from '../base/text-link';
import { FullInstacartLogo } from './InstacartLogo';
import { useAuthenticatedAxios } from '@/context/api-auth';
import { useHousehold } from '@/hooks/use-household';
import { PromoState } from '@/store/promo-notification-store';
export interface DeliveryFeatureNotificationProps {
  onClose?: () => void;
}
export function DeliveryFeatureNotification(
  props: DeliveryFeatureNotificationProps,
) {
  const theme = useTheme();
  const iconColor = theme.colors.black;
  const { onClose = () => {} } = props;
  return (
    <DeliveryFeatureBox>
      <div className="top-bar">
        <Button className="close-icon" onClick={props.onClose}>
          <FontAwesomeIcon icon={faClose} color={iconColor} size="sm" />
        </Button>
      </div>
      <section className="content">
        <div className="header">
          <h2>New! Deliver with Instacart</h2>
          <FullInstacartLogo color="cashew"></FullInstacartLogo>
        </div>
        <p>
          After creating your meal plan, visit your Grocery List to view your
          items in Instacart
        </p>
        <div className="meal-plan-link-container">
          <NextLink href="/plan/" passHref={true} legacyBehavior>
            <TextLink
              className="item"
              onClick={() => {
                onClose();
              }}
            >
              Go to your Meal Planner{' '}
            </TextLink>
          </NextLink>
        </div>
      </section>
    </DeliveryFeatureBox>
  );
}

const DeliveryFeatureBox = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: ${(ctx) => ctx.theme.colors.primary200};
  & .top-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-basis: 0;
  }

  & .close-icon {
    margin: 2px;
  }
  & .content {
    padding: 1rem;
    flex-basis: 1;
    .header {
      display: flex;
      justify-content: space-between;
      flex-direction: column-reverse;
      text-align: center;

      ${({ theme }) => theme.mediaQueries.l} {
        flex-direction: row;
      }
    }

    * {
      margin: 0.5rem;
      color: ${(ctx) => ctx.theme.colors.white};
    }
  }
  & .dismiss * {
  }

  & .meal-plan-link-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .item {
      color: ${(ctx) => ctx.theme.colors.white};
      font-weight: ${(ctx) => ctx.theme.fontWeights.bold};
    }
  }
`;
const PopupContainer = styled('div')`
  position: fixed;
  bottom: 0px;
  z-index: 100;
  width: 100%;
`;

/**
 * Hook to determine whether the Instacart feature notification should be shown
 * @returns
 */
export function useCheckDeliveryNotification() {
  const store = usePromoNotificationStore();
  const state = store((x) => x.instacartDeliveryFeature);
  const setState = store((x) => x.setInstacartDeliveryFeature);

  const { isAuthenticated } = useAuthenticatedAxios();
  const { status: householdStatus, household } = useHousehold();
  const hasSeenQuickstart = !!household?.builtInProfilesCreated;
  const isHydrated = store.persist.hasHydrated();

  const showModal =
    isAuthenticated &&
    // make sure state has been hydrated from storage
    isHydrated &&
    // hasn't been dismissed
    !state.dismissed &&
    // only show if quickstart has already been seen
    householdStatus === 'success' &&
    household?.builtInProfilesCreated;

  return { state, setState, showModal };
}

export function DeliveryFeatureNotificationPopup() {
  // we don't want to show the modal until we're sure it hasn't already been dismissed
  const { state, setState, showModal } = useCheckDeliveryNotification();
  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setState({ seen: state.seen, dismissed: true });
    }
  }, []);
  useEffect(() => {
    let handlerAdded = false;
    if (!state.dismissed) {
      document.addEventListener('keydown', handleKeyDown);
      handlerAdded = true;
    }
    return () => {
      if (handlerAdded) {
        document.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [state.dismissed]);

  return (
    <>
      {showModal && (
        <PopupContainer>
          <Flex
            justifyContent="center"
            flexDirection="column"
            alignItems={['stretch', 'stretch', 'center']}
          >
            <Box
              zIndex="notificationLow"
              maxWidth={['100%', '100%', '100%']}
              width={['100%', '100%', 'auto']}
              maxHeight={['25%', '25%', '10%']}
              height={['auto', 'auto', 'auto']}
              position="relative"
              // flexDirection="column"
              // overflowY={props.allowOverflow ? 'visible' : 'auto'}
            >
              <DeliveryFeatureNotification
                onClose={() => setState({ seen: true, dismissed: true })}
              />
            </Box>
          </Flex>
        </PopupContainer>
      )}
    </>
  );
}
