import React, { ReactNode } from 'react';
import {
  ThemeProvider as EmotionThemeProvider,
  Global,
  css,
} from '@emotion/react';
import { theme } from '@/theme/index';

interface ThemeProviderProps {
  children: ReactNode;
}

const fontFamily = css`
  body {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
`;

const reset = css`
  *,
  *:after,
  *:before {
    box-sizing: border-box;
    margin: 0;
  }
`;

const iconMoon = css`
  @font-face {
    font-family: 'icomoon';
    src: url('/fonts/icomoon.eot') format('eot');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'icomoon';
    src: url('/fonts/icomoon.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'icomoon';
    src: url('/fonts/icomoon.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'icomoon';
    src: url('/fonts/icomoon.svg') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  [class^='icon-'],
  [class*=' icon-'] {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    vertical-align: text-top;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-add-to-list:before {
    content: '\\e901';
  }

  .icon-meal-profiles:before {
    content: '\\e60e';
  }

  .icon-delete:before {
    content: '\\e60b';
  }

  .icon-add:before {
    content: '\\e60f';
  }

  .icon-bullet-list:before {
    content: '\\e60d';
  }

  .icon-pencil-edit:before {
    content: '\\e60c';
  }

  .icon-scorecard:before {
    content: '\\e600';
  }

  .icon-grade:before {
    content: '\\e601';
  }

  .icon-help:before {
    content: '\\e602';
  }

  .icon-settings:before {
    content: '\\e603';
  }

  .icon-shopping:before {
    content: '\\e604';
  }

  .icon-shopping-list:before {
    content: '\\e900';
  }

  .icon-stop:before {
    content: '\\e605';
  }

  .icon-planner:before {
    content: '\\e606';
  }

  .icon-person:before {
    content: '\\e607';
  }

  .icon-recipe:before {
    content: '\\e608';
  }

  .icon-logout:before {
    content: '\\e609';
  }

  .icon-archive:before {
    content: '\\e60a';
  }

  // helper size classes
  .icon-navbar {
    font-size: 18px;
    width: 18px;
    margin-right: 2px;
    line-height: 14px;
  }

  .icon-navbar-sub {
    font-size: 13px;
    width: 13px;
    margin-right: 0;
  }

  .icon-navbar-shopping {
    @extend . icon-navbar-sub;
  }

  .icon-navbar-add {
    font-size: 13px;
    margin-top: 9px;
  }

  // profile box icon

  .icon-profile {
    height: 13px;
    width: 13px;
    border-radius: 2px;
    margin-right: 4px;
    display: inline-block;
  }
`;

/**
 * I need to add this here because i did not find a way to change the color of the tooltip within the component
 * /components/base/tooltip/Tooltip.tsx
 */
const tooltip = css`
  .dnt-rc-tooltip {
    &.rc-tooltip-placement-bottom .rc-tooltip-arrow {
      border-bottom-color: #000;
      opacity: 0.8;
    }
    &.rc-tooltip-placement-top .rc-tooltip-arrow {
      border-top-color: #000;
      opacity: 0.8;
    }
    &.rc-tooltip-placement-right .rc-tooltip-arrow {
      border-right-color: #000;
      opacity: 0.8;
    }
    &.rc-tooltip-placement-left .rc-tooltip-arrow {
      border-left-color: #000;
      opacity: 0.8;
    }
  }
`;

/**
 * Main provider to populate global styles and inject the theme in your application.
 * Your app needs to wrapped in this provider for ui-ds to function.
 * @returns Component
 */

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  return (
    <EmotionThemeProvider theme={theme}>
      <Global
        styles={css`
          ${reset}
          ${fontFamily}
          ${tooltip}
          ${iconMoon}
        `}
      />
      {children}
    </EmotionThemeProvider>
  );
};
