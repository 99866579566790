export const ROUTES: Record<RouteNames, Route> = {
  home: {
    path: '/',
    name: 'Home',
  },
  signup: {
    path: '/signup',
    name: 'Sign up',
  },
  signin: {
    path: '/signin',
    name: 'Sign in',
  },
  household: {
    path: '/household',
    name: 'Household',
  },
  householdEdit: {
    path: '/household/edit',
    name: 'Household edit',
  },
  householdProfileEdit: {
    path: '/household_profiles/[pid]/edit',
    name: 'Household profiles edit',
  },
  householdProfileNew: {
    path: '/household_profiles/new',
    name: 'Household profiles new',
  },
  quickstart: {
    path: '/quickstart',
    name: 'Quickstart',
  },
  mealPlanner: {
    path: '/plan',
    name: 'Meal Planner',
  },
  pantry: {
    path: '/pantry',
    name: 'Pantry',
  },
  recipesPage: {
    path: '/dinners/[date]',
    name: 'Dinner recipes page',
  },
  passwordReset: {
    path: '/password_resets',
    name: 'Password Reset',
  },
  shoppingList: {
    path: '/shopping_lists',
    name: 'Shopping List',
  },
};

export interface Route {
  path: RoutePaths;
  name: string;
}

type RouteNames =
  | 'home'
  | 'signin'
  | 'signup'
  | 'household'
  | 'householdEdit'
  | 'householdProfileNew'
  | 'householdProfileEdit'
  | 'quickstart'
  | 'mealPlanner'
  | 'pantry'
  | 'recipesPage'
  | 'passwordReset'
  | 'shoppingList';
type RoutePaths =
  | '/'
  | '/signin'
  | '/signup'
  | '/household'
  | '/household/edit'
  | '/household_profiles/new'
  | '/household_profiles/[pid]/edit'
  | '/quickstart'
  | '/plan'
  | '/pantry'
  | '/dinners/[date]'
  | '/password_resets'
  | '/shopping_lists';

export const MARKETING_PAGE = 'https://wp2.dinnertime.com/content';
export const HOW_IT_WORKS_PAGE = `${MARKETING_PAGE}/how-it-works`;
export const ABOUT_PAGE = `${MARKETING_PAGE}/about`;
export const BLOG_PAGE = `${MARKETING_PAGE}/table-talk`;
export const NEWS_PAGE = `${MARKETING_PAGE}/news`;
export const PRIVACY_PAGE = `${MARKETING_PAGE}/privacy`;
export const CONTACT_PAGE = `${MARKETING_PAGE}/contact`;
export const TERMS_PAGE = `${MARKETING_PAGE}/content/terms`;
