import { Palette } from '@/constants/palette';
import { Theme } from '@/types/theme-types';

const sizes = {
  s: '32px',
  m: '48px',
  l: '64px',
};

const selectorColors = {
  hoverColor: Palette.white,
  disabledColor: Palette.black,
  focusBorderColor: Palette.white,
  focusMiddleBorder: Palette.secondaryInformative,
  focusInnerBorder: Palette.white,
};

// TODO replace the values with the appropriate ones
export const button: Theme['button'] = {
  borderRadius: '100px',
  borderRadii: {
    s: '100px',
    m: '100px',
    l: '100px',
  },
  sizes,
  selectorColors,
  variants: {
    primary: {
      color: 'secondary',
      backgroundColor: 'cta',
      borderColor: 'cta',
    },
    primaryLight: {
      color: 'secondary',
      backgroundColor: 'primaryLight200',
      borderColor: 'cta',
    },
    secondary: {
      color: 'secondary',
      backgroundColor: 'tabBackgroundColor',
      borderColor: 'borderColor',
    },
    secondaryLight: {
      color: 'secondary',
      backgroundColor: 'secondaryLight',
      borderColor: 'borderColor',
    },
    warning: {
      color: 'secondary',
      backgroundColor: 'warning',
      borderColor: 'cta',
    },
    transparent: {
      color: 'secondary',
      backgroundColor: 'transparent',
      borderColor: 'cta',
    },
    secondaryWarning: {
      color: 'secondary',
      backgroundColor: 'secondaryWarning',
      borderColor: 'cta',
    },
  },
};
