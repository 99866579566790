import { createContext, ReactElement, useCallback, useState } from 'react';
import { useDisclosure } from '@/hooks/use-disclosure';

type AppModalContext = {
  data?: any;
  type?: string;
  isOpen: boolean;
  openModal: (data?: any, type?: string) => void;
  closeModal: () => void;
};

const defaultModal: AppModalContext = {
  isOpen: false,
  openModal: () => {},
  closeModal: () => {},
};

export const ModalContext = createContext<AppModalContext>(defaultModal);

const { Provider: ModalMainProvider } = ModalContext;

type ModalProviderProps = {
  children: ReactElement;
};

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const { isOpen, onClose, onOpen } = useDisclosure(false);
  const [data, setData] = useState<any>();
  const [type, setType] = useState<string>();

  const openModal = useCallback((data: any, type: string = '') => {
    setData(data);
    setType(type);
    onOpen();
  }, []);

  const modalProps = {
    ...defaultModal,
    type,
    data,
    isOpen,
    openModal,
    closeModal: onClose,
  };

  return <ModalMainProvider value={modalProps}>{children}</ModalMainProvider>;
};
