import { Theme } from '@/types/theme-types';

// TODO replace the values with the appropriate ones
export const fontSizes: Theme['fontSizes'] = {
  '6xl': '3.125rem',
  '5xl': '2.75rem',
  '4xl': '1.969rem',
  '3xl': '1.75rem',
  '2xl': '1.5rem',
  xl: '1.375rem',
  l: '1.25rem',
  m: '1.125rem',
  s: '1rem',
  xs: '0.938rem',
  '2xs': '0.875rem',
  '3xs': '0.813rem',
  '4xs': '0.75rem',
  '5xs': '0.688rem',
  '6xs': '0.625rem',
  '7xs': '0.563rem',
  '8xs': '0.438rem',
};

export const fontWeights: Theme['fontWeights'] = {
  extraLight: 100,
  lighter: 200,
  light: 300,
  regular: 400,
  semiBold: 500,
  bold: 600,
  bolder: 700,
  extraBold: 800,
};

export const lineHeights: Theme['lineHeights'] = {
  '4xl': '52px',
  '3xl': '36px',
  '2xl': '32px',
  xl: '30px',
  l: '28px',
  m: '26px',
  s: '24px',
  xs: '23px',
  '2xs': '22px',
  '3xs': '21px',
  '4xs': '20px',
  '5xs': '19px',
  '6xs': '18px',
  '7xs': '17px',
  '8xs': '15px',
  '9xs': '16px',
  '11xs': '12px',
  '12xs': '10px',
};

export const fonts: Theme['fonts'] = {
  body: "'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;",
};
