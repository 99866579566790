import { Theme } from '@/types/theme-types';
import { breakpoints, mediaQueries } from './breakpoints';
import { colors } from './colors';
import * as typography from './typography';
import { textVariants } from './text-variants';
import { space } from './space';
import { radii } from './border-radiuses';
import { zIndices } from './z-indeces';
import { button } from './button';
import { input } from './input';
import { checkbox } from './checkbox';
import { flexSizes } from './flex-sizes';

export const theme: Theme = {
  breakpoints,
  mediaQueries,
  colors,
  button,
  space,
  radii,
  textVariants,
  zIndices,
  input,
  checkbox,
  flexSizes,
  ...typography,
};
