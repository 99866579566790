import React, {
  ComponentType,
  ElementType,
  forwardRef,
  PropsWithChildren,
  ComponentPropsWithRef,
} from 'react';
import styled, { StyledComponent } from '@emotion/styled';
import css, { SystemStyleObject } from '@styled-system/css';
import Image from 'next/legacy/image';

import forkIcon from '/public/images/forkIcon.svg';
import forkIconDark from '/public/images/forkIconDark.svg';
import { Text } from '@/components/base/text';
import { Box } from '@/components/base/box';
import { ButtonVariantKey, DisplayProps, Theme } from '@/types/theme-types';
import { compose, display, margin, MarginProps } from 'styled-system';
import { OpenSansFont } from '@/constants/typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
type StyledButtonProps = {
  variant?: ButtonVariantKey;
};

type NativeButtonProps = {
  /**
   * Callback function for on event
   */
  onClick?: React.MouseEventHandler<HTMLElement>;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>;

export interface ButtonProps
  extends StyledButtonProps,
    Partial<NativeButtonProps & DisplayProps & MarginProps> {
  as?: ElementType;
  size?: 'small' | 'normal';
  isIcon?: boolean;
  isFullWidth?: boolean;
  href?: string;
  loading?: boolean;
}

const getBgColorHover = (theme: Theme, variant: string | undefined) => {
  switch (variant) {
    case 'secondary':
      return theme.colors.secondary;
    case 'secondaryLight':
      return theme.colors.secondaryLight;
    case 'primaryLight':
      return theme.colors.primaryLight100;
    case 'warning':
      return theme.colors.warningBtnHover;
    case 'transparent':
      return theme.colors.transparent;
    case 'secondaryWarning':
      return theme.colors.secondaryWarning;
    default:
      return theme.colors.primary500;
  }
};

const baseCss = css({
  borderWidth: '0',
  fontSize: '3xs',
  fontWeight: '400',
  lineHeight: 's',
  fontFamily: OpenSansFont,
  cursor: 'pointer',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  borderStyle: 'solid',
  position: 'relative',
  outline: 'none',
  border: 'none',
} as SystemStyleObject);

export const StyledButton = styled.button<ButtonProps>`
  ${baseCss};
  border-radius: ${({ theme }) => theme.radii['3xs']};
  ${() => compose(display, margin)}
  cursor: pointer;
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'fit-content')};
  padding: ${({ theme, size }) =>
      size === 'small' ? theme.space['4xs'] : theme.space.xs}px
    ${({ theme }) => theme.space.s}px;
  color: ${({ theme }) => theme.colors.secondary};
  ${({ variant, theme }) => {
    if (variant === 'secondary') {
      return `
        background-color: ${theme.colors.tabBackgroundColor};
        border: 1px solid ${theme.colors.borderColor};
        color: ${theme.colors.warningBtnText};
    `;
    } else if (variant === 'secondaryLight') {
      return `
        background-color: ${theme.colors.secondaryLight};
        border: 1px solid ${theme.colors.borderColor};
        color: ${theme.colors.white};
    `;
    } else if (variant === 'secondaryWarning') {
      return `
        background-color: ${theme.colors.secondaryWarning};
        color: ${theme.colors.secondary};
        border-radius: ${theme.radii['2xs']};
        font-weight: bold;
    `;
    } else if (variant === 'warning') {
      return `
        background-color: ${theme.colors.warning};
    `;
    } else if (variant === 'primaryLight') {
      return `
        background-color: ${theme.colors.primaryLight200};
    `;
    } else if (variant === 'transparent') {
      return `
        background-color: ${theme.colors.transparent};
        color: ${theme.colors.warningBtnText};
    `;
    } else {
      return `
        background-color: ${theme.colors.primary250};
        border-radius: ${theme.radii['2xs']};
    `;
    }
  }}
  &:hover {
    background-color: ${({ variant, theme }) =>
      getBgColorHover(theme, variant)};
  }
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  opacity: ${(props) => (props.loading || props.disabled ? '0.8' : '1')};
`;

const StyledImage = styled(Image)``;

Text.defaultProps = {
  variant: '3xs',
};

/**
 * Factory function to instantiate a component with the default button styling. Useful for
 * creating a nav button or similar which has child elements that cannot be nested in an actual <button>
 * element
 * @param param0
 * @returns
 */
export function makeButton({
  displayName = 'Button',
  ...hocOptions
}: {
  ButtonComponent: StyledComponent<ButtonProps>;
  displayName?: string;
}): ComponentType<ButtonProps> {
  const Component = forwardRef(
    (
      {
        variant = 'primary',
        size = 'normal',
        isIcon = false,
        loading,
        children,
        ...rest
      }: ButtonProps,
      ref,
    ) => {
      const ButtonComponent = hocOptions.ButtonComponent;
      return (
        <ButtonComponent variant={variant} size={size} {...rest}>
          {loading && (
            <Box marginRight={'s'}>
              <FontAwesomeIcon
                spin={true}
                icon={faSpinner}
                width={14}
                height={14}
              />
            </Box>
          )}
          {isIcon && (
            <StyledImage
              style={{
                inset: '6px 0px 0px 0px',
              }}
              src={
                variant === 'secondary' || variant === 'transparent'
                  ? forkIconDark
                  : forkIcon
              }
              alt="icon"
              priority={true}
            />
          )}
          {children}
        </ButtonComponent>
      );
    },
  );
  Component.displayName = displayName;
  return Component;
}

/**
 * Default button instantiation
 */
export const Button = makeButton({ ButtonComponent: StyledButton });
