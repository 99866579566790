import { createContext, PropsWithChildren, useContext } from 'react';
import { default as localforage } from 'localforage';

const DB_NAME = 'dt/localforage-state';
const defaultContext = createContext<undefined | LocalforageContext>(undefined);
export function crateLocalforage() {
  return localforage.createInstance({ name: DB_NAME });
}

export interface LocalforageContext {
  db: LocalForage;
}

export function LocalForageProvider(
  props: PropsWithChildren<{ db?: LocalForage }>,
) {
  const db = props.db ?? crateLocalforage();
  return (
    <defaultContext.Provider value={{ db }}>
      {props.children}
    </defaultContext.Provider>
  );
}

import { isNil } from 'lodash';
export function useLocalforage() {
  const ctx = useContext(defaultContext);
  if (isNil(ctx)) {
    throw new Error(
      `no LocalForageProvider is found in react tree. Be sure to include the provider`,
    );
  }

  return ctx;
}
