import { Box } from '@/components/base/box';

import { Footer } from '@/components/footer';
import React, { ReactNode } from 'react';

export interface LayoutProps {
  children: ReactNode;
  hasPaddingBottom?: boolean;
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  hasPaddingBottom = true,
}) => {
  return (
    <Box>
      <Box paddingBottom={hasPaddingBottom ? ['3xl', '3xl', '3xl', '8xl'] : {}}>
        {children}
      </Box>
      <Footer />
    </Box>
  );
};
