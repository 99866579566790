import { useState } from 'react';

/**
 * @typedef {Object} Disclosure
 * @property {boolean} isOpen - boolean that dictates the state of a modal or other UI element
 * @property {boolean} onClose - function which sets state of isOpen to false
 * @property {boolean} onOpen - function which sets state of isOpen to true
 * @property {boolean} onToggle - function which sets state of isOpen to the opposite state
 * true or false
 */
interface Disclosure {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
}

/**
 * React hook that handles state of modal or ui element
 * @property {boolean} initState - boolean that dictates the initial state of
 * modal or other UI element
 * @returns {Disclosure} state after onClick or user input
 */

export function useDisclosure(initState?: boolean): Disclosure {
  const [isOpen, setIsOpen] = useState(initState || false);

  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };
  const onToggle = () => {
    setIsOpen(!isOpen);
  };
  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
  };
}
