import { Palette } from '@/constants/palette';
import { Theme } from '@/types/theme-types';

export const checkbox: Theme['checkbox'] = {
  variations: {
    primary: {
      selectColor: Palette.primary100,
      color: Palette.gray10,
      hoverColor: Palette.primary100,
      bgImage: '/images/checked-checkbox.svg',
    },
    warning: {
      selectColor: Palette.checkboxWarning,
      color: Palette.gray10,
      hoverColor: Palette.checkboxWarning,
      bgImage: '/images/warning-close.svg',
    },
    warningLite: {
      selectColor: Palette.checkboxWarning,
      color: Palette.gray10,
      hoverColor: Palette.checkboxWarning,
      bgImage: '/images/warning-close.svg', // TODO: Add new warningLite icon
    },
  },
};
