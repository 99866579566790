/**
 * Provides an axios client instance with authentication
 * headers pre-configured
 */

import { AxiosInstance } from 'axios';
import {
  createContext,
  useState,
  useEffect,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';
import { isNil } from 'lodash';
import { logger as baseLogger } from '@/services/logging';
import { getClientFromApiKey } from '@/api-client/axios';
import {
  API_KEY_NAME,
  getExpiresTime,
  getUserApiKey,
  removeApiKey,
  saveApiKey,
} from '@/services/auth-service';
import { useCookies } from 'react-cookie';
const logger = baseLogger.child({
  module: '@/context/api-auth/api-auth-provider',
});
export interface ApiAuthContext {
  axios: AxiosInstance;
  /**
   * Logs in a user with a given
   * @param authHeader
   * @param options.remember If true, set the cookie to remember the user for 1 year
   */
  setAuthHeader(
    authHeader: string | undefined,
    options?: { remember?: boolean },
  ): void;
  logout(): void;
  authHeader: string | undefined;
  isAuthenticated: boolean;
}

const defaultContext = createContext<ApiAuthContext | undefined>(undefined);
export function useAuthenticatedAxios(): ApiAuthContext {
  const ctx = useContext(defaultContext);
  if (isNil(ctx)) {
    throw new Error(
      'No AuthenticatedAxios instance is set, use AuthenticatedAxiosProvider to set one',
    );
  }

  return ctx;
}

export interface AuthenticatedAxiosProps extends PropsWithChildren {
  authHeader: string | undefined;
  setAuthHeader(value: string | undefined): void;
  logout(): void;
}

export function AuthenticatedAxiosProvider({
  authHeader,
  setAuthHeader,
  logout,
  children,
}: AuthenticatedAxiosProps) {
  // NOTE: don't use useMemo to compute client,
  // since this can sometimes fail to update the client when auth is set

  const { client: axios, isAuthenticated } = getClientFromApiKey(authHeader);

  return (
    <defaultContext.Provider
      value={{ axios, isAuthenticated, logout, setAuthHeader, authHeader }}
    >
      {children}
    </defaultContext.Provider>
  );
}
/**
 * Version of AuthenticatedAxiosProvider with authHeader
 * persisted via cookie and react state
 * a cookie
 * @param param0
 */
export function CookieAxiosProvider({
  children,
  authHeader: initialAuthHeader,
}: PropsWithChildren & Partial<Pick<AuthenticatedAxiosProps, 'authHeader'>>) {
  const [cookies, setCookie] = useCookies([API_KEY_NAME]);
  const cookieSetAuthHeader: ApiAuthContext['setAuthHeader'] = (
    value,
    { remember = false } = {},
  ) => {
    const now = new Date();
    setCookie(API_KEY_NAME, value, {
      expires: getExpiresTime(remember),
    });
  };
  const logout = () => {};
  const authHeader = cookies[API_KEY_NAME];
  return (
    <AuthenticatedAxiosProvider
      authHeader={authHeader}
      setAuthHeader={cookieSetAuthHeader}
      logout={logout}
    >
      {children}
    </AuthenticatedAxiosProvider>
  );
}
export const API_KEY_PAGEPROP_NAME = '_authApiHeader';
