import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { GetServerSidePropsContext, NextApiRequest } from 'next';
import getConfig from 'next/config';
import { merge } from 'lodash';
import Cookies from 'js-cookie';

export function clientBaseUrl() {
  const { serverRuntimeConfig } = getConfig();
  if (typeof window !== 'undefined') {
    // if run in browser, use proxied API served through frontend
    return '/';
  } else {
    // when run on the server, directly request from the backend server URL
    return serverRuntimeConfig.apiBaseUrl;
  }
}

export function createClient(options: AxiosRequestConfig<any> = {}) {
  const baseURL = clientBaseUrl();
  return axios.create(
    merge(
      {
        baseURL,
        headers: {
          'Content-type': 'application/json',
        },
      },
      options,
    ),
  );
}

// instead of exposing the axios instance as an exported variable
// eagerly instantiated when the module is loaded, export a function
// that lazily instantiates the shared client. This can avoid some
// unexpected behavior caused by module loading logic (like the
// client getting instantiated before nuxt config or other dependencies are
// loaded), especially in non-standard environments like jest

let _defaultClientInstance: undefined | AxiosInstance;

/**
 * Retrieves a shared, unauthenticated axios instance
 * with the correct base URL configured
 * @returns
 */
export function getApiClient() {
  if (_defaultClientInstance === undefined) {
    _defaultClientInstance = createClient();
  }
  return _defaultClientInstance;
}

export function getClientFromApiKey(apiKey: string | undefined) {
  if (!apiKey) {
    return { client: getApiClient(), isAuthenticated: false };
  } else {
    return {
      client: createClient({
        headers: {
          Authorization: apiKey,
        },
      }),
      isAuthenticated: true,
    };
  }
}

export function getAuthenticatedClient() {
  const apiKey = Cookies.get('api-key');
  return getClientFromApiKey(apiKey);
}

export function getAuthHeaderSSR(req: GetServerSidePropsContext['req']) {
  return req.cookies['api-key'];
}

export function getAuthenticatedClientSSR(
  req: GetServerSidePropsContext['req'],
  fallbackApiKey?: string,
) {
  const apiKey = getAuthHeaderSSR(req) ?? fallbackApiKey;
  return getClientFromApiKey(apiKey);
}
