import Script from 'next/script';
import getConfig from 'next/config';
import { logger } from '@/services/logging';

interface OlarkScriptProps {
  olarkId?: string;
  disable?: boolean;
}

export const OlarkScript = ({
  olarkId: propOlarkId,
  disable = false,
}: OlarkScriptProps) => {
  const olarkId = propOlarkId ?? getConfig().publicRuntimeConfig.olarkId ?? '';
  if (typeof olarkId !== 'string') {
    logger.error(`olarkId must be a string, got ${olarkId}`);
    disable = true;
  } else if (!olarkId) {
    disable = true;
    logger.warn('olarkId is missing');
  }
  // based on install instructions [here](https://www.olark.com/help/html/)
  const olarkEmbedScript = `
   ;(function(o,l,a,r,k,y){if(o.olark)return;        
   r="script";y=l.createElement(r);r=l.getElementsByTagName(r)[0];        
   y.async=1;y.src="//"+a;r.parentNode.insertBefore(y,r);        
   y=o.olark=function(){k.s.push(arguments);k.t.push(+new Date)};        
   y.extend=function(i,j){y("extend",i,j)};        
   y.identify=function(i){y("identify",k.i=i)};        
   y.configure=function(i,j){y("configure",i,j);k.c[i]=j};        
   k=y._={s:[],t:[+new Date],c:{},l:a};        
   })(window,document,"static.olark.com/jsclient/loader.js");       
   /* Add configuration calls below this comment */

   olark.configure('system.is_single_page_application', true);
   olark.identify(${JSON.stringify(olarkId)});
   
   `;

  const scriptHash = simpleHash(olarkEmbedScript);
  if (!disable) {
    return (
      <Script async={true} id={'olark-install-script'} strategy="lazyOnload">
        {olarkEmbedScript}
      </Script>
    );
  } else {
    return <></>;
  }
};

function simpleHash(val: string) {
  var hash = 0;
  for (var i = 0; i < val.length; i++) {
    var char = val.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}
