import { Notification } from '@/components/base/notification';
import styled from '@emotion/styled';
import { Box } from '@/components/base/box';
import { useNotificationCenterStore } from '@/store/notification-center-store';
import { useScoreCardStore } from '@/store/score-card-store';
import { TextLink } from '@/components/base/text-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect } from 'react';
import { Flex } from '@/components/base/flex';
import { usePromoNotificationStore } from '@/store/store-providers';
import { Modal } from '../base/modal';
import {
  DeliveryFeatureNotification,
  DeliveryFeatureNotificationPopup,
} from '../delivery/DeliveryFeatureNotification';

const StyledTopNotificationsWrap = styled(Box)`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndices.notificationCenter};
  top: 0;
  left: 0;
  width: 100%;
`;

const StyledScoreCardLink = styled(TextLink)`
  &:hover {
    text-decoration: underline;
  }
`;

const StyledBottomNotificationWrap = styled(Flex)`
  justify-content: center;
  align-content: center;
  position: fixed;
  bottom: 100px;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndices.notificationCenter};
`;

const StyledBottomNotification = styled(Flex)`
  background: ${({ theme }) => theme.colors.black};
  padding: ${({ theme }) => theme.space.m}px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`;

export const NotificationCenter = ({}) => {
  const notificationMessage = useNotificationCenterStore(
    (state) => state?.notificationMessage,
  );

  const setNotificationMessage = useNotificationCenterStore(
    (state) => state?.setNotificationMessage,
  );

  const scoreCardNotificationMessage = useNotificationCenterStore(
    (state) => state?.scoreCardNotificationMessage,
  );

  const setScoreCardNotificationMessage = useNotificationCenterStore(
    (state) => state?.setScoreCardNotificationMessage,
  );

  const notificationBottomText = useNotificationCenterStore(
    (state) => state?.notificationBottomText,
  );

  const setNotificationBottomText = useNotificationCenterStore(
    (state) => state?.setNotificationBottomText,
  );

  const setViewScoreCardModal = useScoreCardStore(
    (state) => state?.setViewScoreCardModal,
  );

  const { instacartDeliveryFeature, setInstacartDeliveryFeature } =
    usePromoNotificationStore()((x) => ({
      instacartDeliveryFeature: x.instacartDeliveryFeature,
      setInstacartDeliveryFeature: x.setInstacartDeliveryFeature,
    }));

  const showInstacartFeature = !instacartDeliveryFeature.dismissed;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        notificationMessage &&
        (notificationMessage.type === 'success' ||
          notificationMessage.type === 'info')
      )
        setNotificationMessage(null);

      if (scoreCardNotificationMessage) setScoreCardNotificationMessage(null);

      if (notificationBottomText) setNotificationBottomText(null);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [
    notificationMessage,
    notificationBottomText,
    scoreCardNotificationMessage,
  ]);

  return (
    <>
      <DeliveryFeatureNotificationPopup></DeliveryFeatureNotificationPopup>
      <StyledTopNotificationsWrap>
        {notificationMessage && (
          <Notification
            text={notificationMessage.text}
            type={notificationMessage.type}
            onClose={() => {
              setNotificationMessage(null);
            }}
          />
        )}

        {scoreCardNotificationMessage && (
          <Notification
            text={scoreCardNotificationMessage.text}
            type={'success'}
            rightContent={
              <StyledScoreCardLink
                href={'#'}
                onClick={() => {
                  setViewScoreCardModal(true);
                }}
              >
                <FontAwesomeIcon icon={faBookmark} size="sm" /> Scorecard
              </StyledScoreCardLink>
            }
            onClose={() => {
              setScoreCardNotificationMessage(null);
            }}
          />
        )}
      </StyledTopNotificationsWrap>

      <StyledBottomNotificationWrap>
        {notificationBottomText && (
          <StyledBottomNotification>
            {notificationBottomText}
          </StyledBottomNotification>
        )}
      </StyledBottomNotificationWrap>
      <div id="feature-portal"></div>
    </>
  );
};
