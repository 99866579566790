import { useContext, Context } from 'react';
import { MissingProviderError } from './errors';
import { isNil } from 'lodash';

export function useRequiredContext<T>(
  context: Context<T | null>,
  providerName: string,
): T {
  const ctx = useContext(context);
  if (isNil(ctx)) {
    throw new MissingProviderError(providerName);
  } else {
    return ctx;
  }
}
