import React, { useEffect } from 'react';
import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { LocalForageProvider } from '@/context/localforage';

import { ThemeProvider } from '@/context/theme/theme-provider';
import { ModalProvider } from '@/context/modal';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { DNTPage } from '@/types/page';
import { Layout } from '@/components/layout';
import { SEO } from '@/components/seo';
import { AuthGuard } from '@/components/auth-guard/AuthGuard';
import { NotificationCenter } from '@/components/notificationCenter';
import { NextPageContext } from 'next';
import { httpLogger, logger } from '@/services/logging';
import { merge } from 'lodash';
import { API_KEY_PAGEPROP_NAME, CookieAxiosProvider } from '@/context/api-auth';

import { CookiesProvider, Cookies } from 'react-cookie';
import { OlarkScript } from '@/components/olark/OlarkScript';
import getConfig from 'next/config';
// enable immer features for map/set
import { enableMapSet, enablePatches } from 'immer';
import { DefaultStoreProvider } from '@/store/store-providers';
enableMapSet();
enablePatches();

type DNTAppProps = Omit<AppProps, 'Component'> & {
  Component: DNTPage;
  ssrCookies?: string;
};

function MyApp({ Component, pageProps, ssrCookies, ...rest }: DNTAppProps) {
  const isBrowser = typeof window !== 'undefined';
  const cookies =
    isBrowser || !ssrCookies ? undefined : new Cookies(ssrCookies);
  return (
    <CookiesProvider cookies={cookies}>
      <MyAppWithCookies {...{ ...rest, Component, pageProps }} />
    </CookiesProvider>
  );
}

/**
 * Define GetInitialProps to disable automatic static optimization app-wide
 * @param ctx
 * @returns
 */
MyApp.getInitialProps = async function (ctx: AppContext) {
  const { loggingMiddleware } = await import('@/services/logging/http');
  const { req, res } = ctx.ctx;
  if (req && res) {
    loggingMiddleware(req, res);
  }
  const baseInitialProps = await App.getInitialProps(ctx);

  // When being rendered server-side, the renderer does not have global access
  // to the requests' cookies. As a result, we will explicitly pass the cookie
  // header from the request object to the application props
  const ssrCookies = req?.headers?.cookie;
  return merge(baseInitialProps, { pageProps: {}, ssrCookies });
};

/**
 * since we need the react-cookies context, we use a react container
 * component embeded into the main MyApp
 * component
 * @param param0
 * @returns
 */
function MyAppWithCookies({ Component, pageProps }: DNTAppProps) {
  useDevConfigs();
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // staleTime: 10 * 1000, //30 seconds
          },
        },
      }),
  );
  const initialAuthHeader = pageProps[API_KEY_PAGEPROP_NAME];
  const isOlarkDisabled = getConfig().publicRuntimeConfig.disableOlark;
  return (
    <>
      <LocalForageProvider>
        <DefaultStoreProvider>
          <CookieAxiosProvider authHeader={initialAuthHeader}>
            <QueryClientProvider client={queryClient}>
              <Hydrate state={pageProps.dehydratedState}>
                <Head>
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                  />
                </Head>
                <SEO />
                <ModalProvider>
                  <ThemeProvider>
                    <AuthGuard>
                      <>
                        <NotificationCenter />
                        <Layout {...Component}>
                          <Component {...pageProps} />
                        </Layout>
                      </>
                    </AuthGuard>
                  </ThemeProvider>
                </ModalProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </Hydrate>
            </QueryClientProvider>
          </CookieAxiosProvider>
          <OlarkScript disable={isOlarkDisabled} />
        </DefaultStoreProvider>
      </LocalForageProvider>
    </>
  );
}

export default MyApp;

function useDevConfigs() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
    }
  }, []);
}
