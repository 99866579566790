type TextTags =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'span'
  | 'label';

const OpenSansFont =
  "'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;";

export { OpenSansFont };
export type { TextTags };
