import pino from 'pino';

const LOG_LEVEL = (
  process.env.NEXT_LOG_LEVEL ||
  process.env.NEXT_PUBLIC_LOG_LEVEL ||
  'INFO'
).toLowerCase();

export const logger = pino({
  level: LOG_LEVEL,
});

export const httpLogger = logger.child({ module: 'http' });
