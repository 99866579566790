import {
  create,
  StateCreator,
  StoreMutatorIdentifier,
  Mutate,
  StoreApi,
} from 'zustand';
import { createStore } from 'zustand/vanilla';
import { StateStorage } from 'zustand/middleware';
import deepCopy from 'lodash/cloneDeep';

export function localForageStateStore(db: LocalForage): StateStorage {
  return {
    getItem: (name) => db.getItem(name),
    setItem: (name, value) => db.setItem(name, value).then(() => {}),
    removeItem: (name) => db.removeItem(name),
  };
}

/**
 * synchronous State storage that does not persist anything
 */
export function nullStateStore(): StateStorage {
  return {
    getItem: (_name) => null,
    setItem: (_name, _value) => {},
    removeItem: (_name) => {},
  };
}
/**
 * simple identity function to improve type inference
 * of state slices
 * @param fn
 * @returns
 */
export function sliceOf<
  T,
  Mis extends [StoreMutatorIdentifier, unknown][] = [],
  Mos extends [StoreMutatorIdentifier, unknown][] = [],
  U = T,
>(fn: StateCreator<T, Mis, Mos, U>): StateCreator<T, Mis, Mos, U> {
  return fn;
}

export type Write<T extends object, U extends object> = Omit<T, keyof U> & U;

export type Cast<T, U> = T extends U ? T : U;
