import { Theme } from '@/types/theme-types';

export const flexSizes: Theme['flexSizes'] = {
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
};
