import React from 'react';
import Head from 'next/head';

export type SeoProps = {
  description?: string;
  author?: string;
  meta?: Array<{ name: string; content: string }>;
  title?: string;
  imageUrl?: string;
  pageUrl?: string;
  lang?: string;
};

export const SEO: React.FC<SeoProps> = ({
  description = 'DinnerTime',
  author = 'DinnerTime',
  meta = [],
  title = 'DinnerTime',
  imageUrl = '',
  pageUrl = 'https://dinnertime.com',
}) => {
  const propertyMetadata = [
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      property: `og:url`,
      content: pageUrl,
    },
    {
      property: `og:image`,
      content: imageUrl,
    },
    {
      property: `profile:username`,
      content: title,
    },
    {
      property: `og:type`,
      content: `profile`,
    },
    {
      property: `og:locale`,
      content: `en_US`,
    },
    {
      property: `og:site_name`,
      content: `DinnerTime`,
    },
    {
      property: `og:image:secure_url`,
      content: imageUrl,
    },
  ];

  const nameMetadata = [
    {
      name: `description`,
      content: description,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: author,
    },
    {
      name: `twitter:image`,
      content: imageUrl,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: description,
    },
    {
      name: 'twitter:domain',
      content: 'DinnerTime',
    },
  ].concat(meta);

  return (
    <Head>
      <title>{title}</title>
      {propertyMetadata.map(({ property, content }, i) => (
        <meta key={i} property={property} content={content} />
      ))}

      {nameMetadata.map(({ name, content }, i) => (
        <meta key={i + name} name={name} content={content} />
      ))}
    </Head>
  );
};

SEO.defaultProps = {
  lang: `en-US`,
  meta: [],
};
