import React from 'react';
import styled from '@emotion/styled';

import pantryIcon from '/public/images/pantryIcon.svg';
import pantryIconHover from '/public/images/pantryIconHover.svg';
import pantryIconSelected from '/public/images/pantryIconSelected.svg';
import filterIcon from '/public/images/filterIcon.svg';
import leftArrow from '/public/images/leftArrowIcon.svg';
import { theme } from '@/theme/index';
import { Button } from '@/components/base/buttons/Button';

interface IconButtonType extends React.ButtonHTMLAttributes<HTMLElement> {
  isSelected?: boolean;
  /**
   * Callback function for onClick event
   */
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const commonStyles = `
  border: 1px solid ${theme.colors.iconBtnBorder};
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 34px;
  width: 34px;
  &:hover {
    background-color: ${theme.colors.iconBtnHover};
  }
`;

export const ArrowLeftButton = styled.div<IconButtonType>`
  background: ${({ theme }) => theme.colors.secondary} url('${leftArrow.src}')
    no-repeat center;
  background-color: ${({ isSelected, theme }) =>
    isSelected && theme.colors.iconBtnHover};
  ${commonStyles}
`;

export const ArrowRightButton = styled(ArrowLeftButton)`
  transform: rotate(180deg);
`;

export const FilterButton = styled.div<IconButtonType>`
  background: ${({ theme }) => theme.colors.secondary} url('${filterIcon.src}')
    no-repeat center;
  background-color: ${({ isSelected }) =>
    isSelected && theme.colors.iconBtnHover};
  ${commonStyles}
`;

export const PantryButton = styled.button<IconButtonType>`
  /* CSS*/
  background: url('${({ isSelected }) =>
      isSelected ? pantryIconSelected.src : pantryIcon.src}')
    no-repeat;
  cursor: pointer;
  display: inline-block;
  height: ${pantryIcon.height}px;
  width: ${pantryIcon.width}px;
  border-style: none;
  :disabled {
    filter: grayscale(100%);
  }
`;

export const RoundedButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white};
  ${commonStyles}
  color: ${({ theme }) => theme.colors.buttonGray35};
  padding: ${({ theme }) => theme.space['2xs']}px;
  transition: background-color 0.5s ease;
`;
