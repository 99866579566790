import { useAuthenticatedAxios } from '@/context/api-auth';

import { memoize } from 'lodash';
import { redirect } from 'next/dist/server/api-utils';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import type { UrlObject } from 'url';
import { Cookies } from 'react-cookie';

export interface AuthStateInput {
  isAuthenticated: boolean;
}

interface IsAuthorizedResult {
  isAuthorized: true;
}

interface NotAuthorizedResult {
  isAuthorized: false;
  redirectTo: string;
}

export type PermissionCheckResult = IsAuthorizedResult | NotAuthorizedResult;

export function getPermissionCheck({
  path,
  isAuthenticated,
}: {
  path: string;
  isAuthenticated: boolean;
}): PermissionCheckResult {
  const [preQueryPath] = path.split('?');
  if (isAuthenticated) {
    if (isAllowedWhenAuthenticated(preQueryPath)) {
      return { isAuthorized: true };
    } else {
      return { isAuthorized: false, redirectTo: '/' };
    }
  } else {
    if (isPublicPath(preQueryPath)) {
      return { isAuthorized: true };
    } else {
      return {
        isAuthorized: false,
        redirectTo: SIGNIN_URL,
      };
    }
  }
}

export interface UseAuthorizationStateProps {
  onChangeStart?: () => unknown;

  onCheckPermissions?: (permissions: PermissionCheckResult) => unknown;
}
export function useAuthorizationState() {
  const cachedGetPermissionCheck = getPermissionCheck;
  const [hookCookies] = useCookies(['api-key']);
  return {
    getPermissionCheck: (path: string) => {
      // useCookies hook doesn't work in certain routing contexts, so we'll use the universal-cookie
      // api directly
      const cookies = new Cookies();
      const isAuthenticated = cookies.get('api-key');
      return cachedGetPermissionCheck({ path, isAuthenticated });
    },
  };
}

const SIGNIN_URL = '/signin';
const PUBLIC_URLS: (string | RegExp)[] = [
  SIGNIN_URL,
  '/signup',
  '/content',
  '/logout',
  '/password_resets/new',
  /password_resets\/([A-Za-z0-9]+)\/edit/,
];

/**
 * Paths not allowed to be accessed by authenticated users
 */
const FORBIDDEN_WHEN_AUTHENTICATED_URLS = [
  '/password_resets/new',
  /password_resets\/([A-Za-z0-9]+)\/edit/,
  // otherwise forbidden, but auth handled in getServerSideProps
  // '/signin',
  // '/signup',
];
/**
 * Returns true if an authenticated user is allowed to access this path.
 *
 * Is memoized, since it will be called every page access
 */
export const isAllowedWhenAuthenticated = (path: string) => {
  return !isIncludedInList(path, FORBIDDEN_WHEN_AUTHENTICATED_URLS);
};

const isPublicPath = (path: string): boolean => {
  return isIncludedInList(path, PUBLIC_URLS);
};

export function isIncludedInList(path: string, patterns: (string | RegExp)[]) {
  for (const pattern of patterns) {
    if (pattern instanceof RegExp && pattern.test(path)) {
      return true;
    } else if (path === pattern) {
      return true;
    }
  }
  return false;
}
