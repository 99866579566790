import { OpenSansFont } from '@/constants/typography';
import { Theme } from '@/types/theme-types';

// TODO replace the values with the appropriate ones
export const textVariants: Theme['textVariants'] = {
  default: {
    fontSize: '2xs',
    fontWeight: '400',
    lineHeight: '2xs',
    fontFamily: OpenSansFont,
  },
  inherit: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
  },
  '5xlBold': {
    fontSize: '5xl',
    fontWeight: '800',
    lineHeight: '5xl',
    fontFamily: OpenSansFont,
  },
  '5xl': {
    fontSize: '5xl',
    fontWeight: '400',
    lineHeight: '5xl',
    fontFamily: OpenSansFont,
  },
  '4xlBold': {
    fontSize: '4xl',
    fontWeight: '800',
    lineHeight: '4xl',
    fontFamily: OpenSansFont,
  },
  '4xl': {
    fontSize: '4xl',
    fontWeight: '400',
    lineHeight: '4xl',
    fontFamily: OpenSansFont,
  },
  '4xlLight': {
    fontSize: '4xl',
    fontWeight: '300',
    lineHeight: '4xl',
    fontFamily: OpenSansFont,
  },
  '3xlBold': {
    fontSize: '3xl',
    fontWeight: '800',
    lineHeight: '3xl',
    fontFamily: OpenSansFont,
  },
  '3xl': {
    fontSize: '3xl',
    fontWeight: '400',
    lineHeight: '3xl',
    fontFamily: OpenSansFont,
  },
  '3xlLight': {
    fontSize: '3xl',
    fontWeight: '300',
    lineHeight: '3xl',
    fontFamily: OpenSansFont,
  },
  '2xlBold': {
    fontSize: '2xl',
    fontWeight: '800',
    lineHeight: '2xl',
    fontFamily: OpenSansFont,
  },
  '2xl': {
    fontSize: '2xl',
    fontWeight: '400',
    lineHeight: '2xl',
    fontFamily: OpenSansFont,
  },
  xlBold: {
    fontSize: 'xl',
    fontWeight: '800',
    lineHeight: 'xl',
    fontFamily: OpenSansFont,
  },
  xl: {
    fontSize: 'xl',
    fontWeight: '400',
    lineHeight: 'xl',
    fontFamily: OpenSansFont,
  },
  xlLight: {
    fontSize: 'xl',
    fontWeight: '300',
    lineHeight: 'xl',
    fontFamily: OpenSansFont,
  },
  lBold: {
    fontSize: 'l',
    fontWeight: '800',
    lineHeight: 'l',
    fontFamily: OpenSansFont,
  },
  l: {
    fontSize: 'l',
    fontWeight: '400',
    lineHeight: 'l',
    fontFamily: OpenSansFont,
  },
  lLight: {
    fontSize: 'l',
    fontWeight: '300',
    lineHeight: 'l',
    fontFamily: OpenSansFont,
  },
  mBold: {
    fontSize: 'm',
    fontWeight: '800',
    lineHeight: 'm',
    fontFamily: OpenSansFont,
  },
  m: {
    fontSize: 'm',
    fontWeight: '400',
    lineHeight: 'm',
    fontFamily: OpenSansFont,
  },
  mLight: {
    fontSize: 'm',
    fontWeight: '300',
    lineHeight: 'm',
    fontFamily: OpenSansFont,
  },
  sBold: {
    fontSize: 's',
    fontWeight: '800',
    lineHeight: 's',
    fontFamily: OpenSansFont,
  },
  s: {
    fontSize: 's',
    fontWeight: '400',
    lineHeight: 's',
    fontFamily: OpenSansFont,
  },
  sLight: {
    fontSize: 's',
    fontWeight: '300',
    lineHeight: 's',
    fontFamily: OpenSansFont,
  },
  xsBold: {
    fontSize: 'xs',
    fontWeight: '800',
    lineHeight: 'xs',
    fontFamily: OpenSansFont,
  },
  xs: {
    fontSize: 'xs',
    fontWeight: '400',
    lineHeight: 'xs',
    fontFamily: OpenSansFont,
  },
  '2xsBold': {
    fontSize: '2xs',
    fontWeight: '800',
    lineHeight: '2xs',
    fontFamily: OpenSansFont,
  },
  '2xs': {
    fontSize: '2xs',
    fontWeight: '400',
    lineHeight: '2xs',
    fontFamily: OpenSansFont,
  },
  '2xsLight': {
    fontSize: '2xs',
    fontWeight: '300',
    lineHeight: '2xs',
    fontFamily: OpenSansFont,
  },

  '3xs': {
    fontSize: '3xs',
    fontWeight: '400',
    lineHeight: '3xs',
    fontFamily: OpenSansFont,
  },
  '3xsBold': {
    fontSize: '3xs',
    fontWeight: '800',
    lineHeight: '3xs',
    fontFamily: OpenSansFont,
  },
  '3xsLight': {
    fontSize: '3xs',
    fontWeight: '300',
    lineHeight: '2xs',
    fontFamily: OpenSansFont,
  },
  '4xs': {
    fontSize: '4xs',
    fontWeight: '400',
    lineHeight: '4xs',
    fontFamily: OpenSansFont,
  },
  '4xsBold': {
    fontSize: '4xs',
    fontWeight: '800',
    lineHeight: '4xs',
    fontFamily: OpenSansFont,
  },
  '4xsLight': {
    fontSize: '4xs',
    fontWeight: '300',
    lineHeight: '4xs',
    fontFamily: OpenSansFont,
  },
  '5xs': {
    fontSize: '5xs',
    fontWeight: '400',
    lineHeight: '5xs',
    fontFamily: OpenSansFont,
  },
  '5xsLight': {
    fontSize: '5xs',
    fontWeight: '300',
    lineHeight: '5xs',
    fontFamily: OpenSansFont,
  },
  '5xsBold': {
    fontSize: '5xs',
    fontWeight: '800',
    lineHeight: '5xs',
    fontFamily: OpenSansFont,
  },
  '6xs': {
    fontSize: '6xs',
    fontWeight: '400',
    lineHeight: '6xs',
    fontFamily: OpenSansFont,
  },
  '6xsBold': {
    fontSize: '6xs',
    fontWeight: '800',
    lineHeight: '6xs',
    fontFamily: OpenSansFont,
  },
  '6xsLight': {
    fontSize: '6xs',
    fontWeight: '300',
    lineHeight: '6xs',
    fontFamily: OpenSansFont,
  },

  '7xs': {
    fontSize: '7xs',
    fontWeight: '400',
    lineHeight: '7xs',
    fontFamily: OpenSansFont,
  },
  '7xsBold': {
    fontSize: '7xs',
    fontWeight: '800',
    lineHeight: '7xs',
    fontFamily: OpenSansFont,
  },
  '7xsLight': {
    fontSize: '7xs',
    fontWeight: '300',
    lineHeight: '7xs',
    fontFamily: OpenSansFont,
  },
  '8xs': {
    fontSize: '8xs',
    fontWeight: '400',
    lineHeight: '8xs',
    fontFamily: OpenSansFont,
  },
  '8xsBold': {
    fontSize: '8xs',
    fontWeight: '800',
    lineHeight: '8xs',
    fontFamily: OpenSansFont,
  },
  '8xsLight': {
    fontSize: '8xs',
    fontWeight: '300',
    lineHeight: '8xs',
    fontFamily: OpenSansFont,
  },
};
