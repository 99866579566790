import React from 'react';
import styled from '@emotion/styled';
import { Text } from '@/components/base/text';

type TextButtonTypes = {
  isUnderline?: boolean;
  /**
   * Callback function for onClick event
   */
  onClick?: React.MouseEventHandler<HTMLElement>;
};

export const TextButton = styled(Text)<TextButtonTypes>`
  cursor: pointer;
  ${({ isUnderline }) =>
    isUnderline &&
    `  text-decoration: underline;
   `};
  &:hover {
    color: ${({ theme }) => theme.colors.primary100}
`;

TextButton.defaultProps = {
  variant: '4xs',
  color: 'text',
};
