import { localForageStateStore, nullStateStore } from '@/utils/stores';
import { create, StateCreator } from 'zustand';
import { persist, StateStorage, devtools } from 'zustand/middleware';
export interface PromoState {
  seen: boolean;
  dismissed: boolean;
}

interface State {
  /**
   * popup announcing the new instacart feature
   */
  instacartDeliveryFeature: PromoState;
  /**
   * Tooltip to be shown before sending shopping cart to instacart, with
   * instructions on how the system works
   */
  instacartInstructions: PromoState;
}

interface Actions {
  setInstacartDeliveryFeature: (value: PromoState) => void;
  setInstacartInstructions: (value: PromoState) => void;
}
const defaultPromoState = {
  seen: false,
  dismissed: false,
};
export type PromoNotificationState = State & Actions;
const initialState: State = {
  instacartDeliveryFeature: {
    ...defaultPromoState,
  },
  instacartInstructions: { ...defaultPromoState },
};

export const promoNotificationState: StateCreator<
  PromoNotificationState,
  any
> = (set) => {
  return {
    ...initialState,
    setInstacartDeliveryFeature: (value) =>
      set({ instacartDeliveryFeature: value }),
    setInstacartInstructions: (value) => set({ instacartInstructions: value }),
  };
};

const DB_NAME = 'PROMO_NOTIFICATION_STORE';

export function createPersistentStoreHook(getStorage?: () => StateStorage) {
  const storeFn = getStorage ?? nullStateStore;
  return create(
    persist(
      devtools(promoNotificationState, {
        enabled: process.env.NODE_ENV === 'development',
        name: 'promoNotificationStore',
      }),
      {
        name: DB_NAME,
        getStorage: storeFn,
      },
    ),
  );
}

export function createStoreHook(db: LocalForage) {
  return createPersistentStoreHook(() => localForageStateStore(db));
}
