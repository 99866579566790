import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import css, { SystemStyleObject } from '@styled-system/css';
import { ColorKey } from '@/types/theme-types';
import { Text, TextProps } from '@/components/base/text';

export type TextLinkProps = TextProps & {
  /**
   * The URL that the hyperlink points to
   */
  href?: string;
  /**
   * Add target props for link
   */
  /* There is an issue with React not exporting this type, and ts throws an error.
    I'm ignoring the error, as I haven't seen a propper solution yet
    https://stackoverflow.com/questions/46153426/namespace-react-has-no-exported-member-inputhtmlattributes-and-interface-me
    upgrading the types does not work either
  */
  // @ts-ignore
  target?: React.HTMLAttributeAnchorTarget;
  /**
   * Defines the relationship between a linked resource and the current document
   */
  rel?: string;

  /**
   * Resposible to toggle the underline decoreation of the TextLink
   */
  showTextDecoration?: boolean;

  /**
   * The color of the TextLink
   */
  color?: ColorKey;

  /**
   * onClick callback
   */
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

export const baseStyles = css({
  display: 'inline-block',
  cursor: 'pointer',
} as SystemStyleObject);

const Link = styled(Text)<TextLinkProps>`
  ${baseStyles}
  text-decoration: ${({ showTextDecoration: showDecoration }) =>
    showDecoration ? 'underline' : 'none'};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: 300;

  ${({ theme }) => theme.mediaQueries.m} {
    font-size: ${({ theme }) => theme.fontSizes.s};
  }
`;

export const TextLink = forwardRef(function TextLink(
  { variant, color, showTextDecoration = false, ...props }: TextLinkProps,
  ref,
) {
  return (
    <Link
      {...props}
      as="a"
      variant={variant ?? 'inherit'}
      color={color ?? 'link'}
      showTextDecoration={showTextDecoration}
    />
  );
});
