import { getAuthenticatedClient } from '@/api-client/axios';
import { logger as baseLogger } from '@/services/logging';
import { PaginatedListV1 } from '@/types/api-models';
import { AxiosInstance } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';

import { isNil } from 'lodash';
import { useEffect, useMemo } from 'react';
const logger = baseLogger.child({ module: '@/hooks/use-household' });

export type Store = {
  id: number;
  name: string;
  isUnion: boolean;
  priceMultiplier: number;
  recEngineId: string;
};

export type Household = {
  id: number;
  brontoSubscriptionStatus?: string;
  builtInProfilesCreated?: boolean;
  doctorPermission?: boolean;
  expireDate?: string;
  firstName: string;
  lastActivityDate: string;
  lastName: string;
  lastReminderDate?: string;
  lastStore?: Store;
  lastStoreFreetext?: string;
  mobilePhone?: string;
  monthlyPoints?: number;
  nextMessage?: number;
  partnerIdentifier?: string;
  planDuration: string;
  referer?: string;
  resourceUri: string;
  sawMealPlan: boolean;
  sawProfile: boolean;
  sawRecipeBox: boolean;
  sawRecipePage: boolean;
  sawShoppingList: boolean;
  sendDailyEmails: boolean;
  sendNewsletterEmails: boolean;
  sendPromoEmails: boolean;
  sendSurveyEmails: boolean;
  showBasicQuickstart: boolean;
  showHeartcheck: boolean;
  showPeapodMealKits: boolean;
  showUnionLogos: boolean;
  status: string;
  storeChainFreetext?: string;
  stripeCustomerId?: string;
  suppressDailyEmails: boolean;
  totalPoints: number;
  yearToDatePoints: number;
  zip: number;
  standingShoppingTrip: string;
  referralCode?: string;
};

const transformHouseholdResponse = (householdV1: any): Household => {
  return {
    id: householdV1.id,
    builtInProfilesCreated: householdV1.built_in_profiles_created,
    firstName: householdV1.first_name,
    lastActivityDate: householdV1.last_activity_date,
    lastName: householdV1.last_name,
    planDuration: householdV1.plan_duration,
    resourceUri: householdV1.resource_uri,
    sawMealPlan: householdV1.saw_meal_plan,
    sawProfile: householdV1.saw_profile,
    sawRecipeBox: householdV1.saw_recipe_box,
    sawRecipePage: householdV1.saw_recipe_page,
    sawShoppingList: householdV1.saw_shopping_list,
    sendDailyEmails: householdV1.send_daily_emails,
    sendNewsletterEmails: householdV1.send_newsletter_emails,
    sendPromoEmails: householdV1.send_promo_emails,
    sendSurveyEmails: householdV1.send_survey_emails,
    showBasicQuickstart: householdV1.show_basic_quickstart,
    showHeartcheck: householdV1.show_heartcheck,
    showPeapodMealKits: householdV1.show_peapod_meal_kits,
    showUnionLogos: householdV1.show_union_logos,
    status: householdV1.status,
    suppressDailyEmails: householdV1.suppress_daily_emails,
    totalPoints: householdV1.total_points,
    yearToDatePoints: householdV1.year_to_date_points,
    zip: householdV1.zip,
    mobilePhone: householdV1.mobile_phone,
    monthlyPoints: householdV1.monthly_points,
    standingShoppingTrip: householdV1.standing_shopping_trip,
    referralCode: householdV1.referral_code,
    ...(householdV1.last_store && {
      lastStore: {
        id: householdV1.last_store.id,
        name: householdV1.last_store.name,
        isUnion: householdV1.last_store.is_union,
        priceMultiplier: householdV1.last_store.price_multiplier,
        recEngineId: householdV1.last_store.rec_engine_id,
      },
    }),
  };
};

export const FETCH_HOUSEHOLD_KEY = ['query-api-authenticated', 'household'];
export function fetchHousehold(client: AxiosInstance) {
  return async () => {
    {
      const { data } = await client.get<PaginatedListV1<any>>(
        '/api/v1/household',
      );
      if (!data?.objects?.[0]) {
        throw new Error('household is not available');
      }

      return transformHouseholdResponse(data.objects[0]);
    }
  };
}

export const useHousehold = (
  queryOptions: Omit<UseQueryOptions<Household>, 'queryFn' | 'queryKey'> = {},
) => {
  const { client, isAuthenticated } = getAuthenticatedClient();

  // disable query if not authenticated
  const enabled = isNil(queryOptions.enabled)
    ? isAuthenticated
    : queryOptions.enabled && isAuthenticated;
  const {
    data: household,
    isLoading,
    isError,
    ...hooks
  } = useQuery(FETCH_HOUSEHOLD_KEY, fetchHousehold(client), {
    staleTime: 60 * 1000,
    ...queryOptions,
    enabled,
  } as Omit<UseQueryOptions<Household>, 'queryFn' | 'queryKey'>);

  return { household, isLoading, isError, ...hooks };
};
