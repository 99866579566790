import { Theme } from '@/types/theme-types';

export const zIndices: Theme['zIndices'] = {
  behind: -1,
  background: 0,
  planDayCalendarMoveImage: 1,
  planDayCalendarMoveButton: 2,
  content: 100,
  interaction: 200,
  navigation: 300,
  navigationHigh: 350,
  notificationLow: 400,
  notificationMed: 450,
  notificationHigh: 475,
  datepicker: 1097,
  notificationCenter: 2000,
};
